<template>
	<div>
		<common-table
				:itemScope="['person', 'expressDelivery', 'deliveryAction']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				:customHtml="true"
				ref="ct"
				:btnActionEvent="btnActionEvent"
				pageTitle="发货管理"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="fixedConfig"
						:othersConfig="othersConfig"
						@search="search"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>

			<template v-slot:person="{ item }">
				<div class="row2">{{ item.bookername }}</div>
				<div class="row2">{{ item.tel }}</div>
			</template>

			<template v-slot:expressDelivery="{ item }">
				<div class="row2">{{ item.company }}</div>
				<div class="row2">{{ item.deliveryno }}</div>
			</template>

			<template v-slot:deliveryAction="{ item }">
				<v-btn v-if="item.sendstatus == 0 ||
							 item.sendstatus == 1 ||
							 item.sendstatus == 2"
					depressed color="primary" @click="deliveryAction(item.bookingid)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-liucheng</v-icon>
					{{ item.sendsdesc }}
				</v-btn>
				<div v-else>
					{{ item.sendsdesc }}
				</div>
			</template>

		</common-table>

		<template v-if="dialogAction">
		  <exhibition-data-box :width="936" :contentHeight="500" :dialog.sync="dialogAction" title="发货流程">
			<div class="white rounded-lg px-16">
				<v-stepper alt-labels :value="Number(current.progress) + 1">
				  <v-stepper-header>
					<v-stepper-step step="1" :complete="current.progress > 0">
						待备货
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="2" :complete="current.progress > 1">
						待发货
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="3" :complete="current.progress > 2">
						待收货
					</v-stepper-step>
				  </v-stepper-header>
				</v-stepper>
			</div>

			<div v-if="current.progress == 0"
				 v-for="(gift, index) in giftList"
				 :key="index"
				 class="white rounded-lg mt-6 pa-6">

		        <v-row>
					<v-col v-for="(item, i) in gift"
						   :key="i"
						   :cols="i == 0 ? 12 : 4">
						<strong v-if="i == 0"
							style="font-size: 18px;">
							{{ item.value }}
						</strong>
						<v-text-field v-else
							autocomplete="off" hide-details dense readonly
							:label="item.title"
							v-model="item.value"
						></v-text-field>
					</v-col>
		        </v-row>

		    </div>

			<div v-if="current.progress == 1" class="white rounded-lg mt-6 pa-6">
				<div class="modal-title">物流信息</div>
				<v-form ref="formCompany" lazy-validation>
					<v-row>
						<v-col cols="4">
							<w-autocomplete
							    dense
							    class="mr-2"
							    :items="companyList"
							    label="物流公司"
							    @change="company = $event"
								:value="company"
								:rules="[v => !!v || '物流公司不能为空']"
							></w-autocomplete>
						</v-col>
						<v-col cols="4">
							<v-text-field autocomplete="off" dense required
								:rules="[v => !!v || '物流单号不能为空']"
								label="物流单号 *"
								v-model="deliveryno"
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</div>

			<div v-if="current.progress == 2" class="white rounded-lg mt-6 pa-6">
				<div class="modal-title">确认收货方法</div>
				<div style="font-size: 14px;">
					1.买家在小程序/PC官网上点击确认收货按钮。<br>
					2.商家设置购买规则，根据查询快递单号确认客户签收后，订单自动变成确认收货状态。
				</div>
			</div>

		    <template v-slot:footer>
		      <div class="text-md-center mt-6">
		        <v-btn
					v-if="current.progress > 0"
		            depressed
		            color="primary"
		            width="87"
		            outlined
					:loading="prevLoading"
		            @click="save(false)"
		        >
		          上一步
		        </v-btn>
		        <v-btn
		            class="ml-6"
		            depressed
		            color="primary"
		            width="87"
		            :loading="nextLoading"
		            @click="save(true)"
		        >
		          <span v-if="current.progress == 2">完成</span>
				  <span v-else>下一步</span>
		        </v-btn>
		      </div>
		    </template>
		  </exhibition-data-box>
		</template>

		<template v-if="dialog">
		  <exhibition-data-box :width="936" :contentHeight="500" :dialog.sync="dialog" title="订单详情">
			 <div class="pt-4">
		        <v-row>
					<v-col v-for="(item, index) in detail"
						   :key="index"
						   :cols="item.cols">
						<v-text-field autocomplete="off" hide-details dense readonly
							:label="item.title"
							v-model="item.value"
						></v-text-field>
					</v-col>
		        </v-row>
			 </div>
		  </exhibition-data-box>
		</template>

	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/deliveryManage.js';
import formatterField from '../formatter.vue';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsLog from '../common-order-js/details-log.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';
import ec from './expressCompany.js'

export default {
	// 预约记录
	components: {advancedSearch, formatterField, exhibitionDataBox, formMenu, wAutocomplete},
	mixins: [commonCurd, detailsLog],
	data() {
		return {
			api,
			dialog: false,
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '物流详情'
				}
			],
			headers: [
				{text: '订单号', value: 'bookingid'},
				{text: '商品信息', value: 'productdesc', width: '189px'},
				{text: '收件人信息', value: 'person'},
				{text: '收货地址', value: 'address', width: '270px'},
				{text: '物流信息', value: 'expressDelivery'},
				{text: '发货状态', value: 'deliveryAction'},
				{text: '下单时间', value: 'createdate'},
				{text: '操作', value: 'actions', width: '80px'}
			],
			fixedConfig: [
				{type: 'input', label: '输入订单号', property: 'bookingid'}
			],
			othersConfig: [
				{type: 'select', label: '发货状态', property: 'sendstatus', items: []},
				{type: 'dateTime', label: '开始日期', property: 'startdate'},
				{type: 'dateTime', label: '结束日期', property: 'enddate'}
			],

			detail: [],
			giftList: [],
			current: {},
			prevLoading: false,
			nextLoading: false,
			deliveryno: '',
			dialogAction: false,

			ec: {},
			companyList: [],
			company: ''
		}
	},
	methods: {
		deliveryAction(bookingid) {
			this.axios.post(api.logistics, {bookingid: bookingid}).then(res=> {
				this.current = res.data
				this.company = this.current.company
				this.deliveryno = this.current.deliveryno
				this.giftList = this.formatGifts(res.data.gifts)
				this.dialogAction = true
			})
		},

		formatGifts(gifts) {
			let res = []
			for(let gift of gifts) {
				let g = [
					{ title: '商品名称', value: gift['giftName']},
					{ title: '订单号', value: gift['bookingid']},
					{ title: '商品规格', value: gift['giftitemName']},
					{ title: '下单时间', value: gift['createtime']},
					{ title: '商品单价', value: gift['price']},
					{ title: '商品数量', value: gift['num']},
					{ title: '收件人姓名', value: gift['name']},
					{ title: '收件人电话', value: gift['tel']},
					{ title: '收货地区', value: gift['area']},
					{ title: '详细地址', value: gift['address']},
				]
				res.push(g)
			}
			return res
		},

		save(next) {
			if (next && this.current.progress == 2) {
				this.dialogAction = false
				this.list()
				return
			}

			if (next && this.current.progress == 1) {
				if (!this.$refs.formCompany.validate()) {
					return
				} else {
					this.current.company = this.company
					this.current.deliveryno = this.deliveryno
				}
			}
			next ? this.nextLoading = true : this.prevLoading = true
			this.axios.post(api.save, {
				bookingid: this.current.bookingid,
				company: this.current.company,
				deliveryno: this.current.deliveryno,
				next: next,
				progress: this.current.progress
			}).then(res => {
				next ? this.nextLoading = false : this.prevLoading = false
				this.list()
				this.deliveryAction(this.current.bookingid)
			})

		},

		otherTableOperate(e, item) {
			if (e === this.btnActionEvent[0].event) {
				this.axios.post(api.load, {bookingid: item.bookingid}).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						let g = res.data
						this.detail = [
							{ title: '订单号', value: g.bookingid, cols: 4 },
							{ title: '预订平台订单编号', value: g.outid, cols: 4 },
							{ title: '发货状态', value: g.sendstatus, cols: 4 },
							{ title: '预订人姓名', value: g.bookername, cols: 4 },
							{ title: '预订人手机号', value: g.tel, cols: 4 },
							{ title: '商品总金额', value: g.amount, cols: 4 },
							{ title: '实际支付金额', value: g.payamount, cols: 4 },
							{ title: '支付方式', value: g.payment, cols: 4 },
							{ title: '支付时间', value: g.paytime, cols: 4 },
							{ title: '支付订单号', value: g.payno, cols: 4 },
							{ title: '支付流水号', value: g.payid, cols: 4 },
							{ title: '创建时间', value: g.createdate, cols: 4 },
							{ title: '收货地区', value: g.area, cols: 4 },
							{ title: '详细地址', value: g.address, cols: 8 },
							{ title: '物流公司', value: g.company, cols: 4 },
							{ title: '物流单号', value: g.deliveryno, cols: 4 },
						]
					}
					this.dialog = true
				})
			}
		},
		companyText(code) {
			let res = this.companyList.find(item => item.code == code)
			if (res) {
				return res.desc
			}
			return ''
		}
	},
	mounted() {
		this.ec = ec
		this.companyList = ec.list

		this.axios.post(this.select_data, {keyname: 'sendstatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				// console.log('sendstatus', res)
				this.othersConfig[0].items = res.data[0].values
			}
		});
	},
}
</script>

<style scoped lang="scss">
	.row2 {
		height: 19px;
		line-height: 19px;
	}

	.modal-title {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	::v-deep .v-sheet.v-stepper:not(.v-sheet--outlined) {
		box-shadow: none;
	}
</style>
